import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import _get from 'lodash/get';

import s from './Accounts.module.scss';
import Box from '@/shared/ui/Box';
import Main from '~/Accounts/components/Main';
import SubaccountsActive from '~/Accounts/components/SubaccountsActive';
import SubaccountsInactive from '~/Accounts/components/SubaccountsInactive';
import Details from '~/Accounts/components/Details';
import Payouts from '~/Accounts/components/Payouts';
import CreateSubAccountModal from '~/Accounts/components/CreateSubAccountModal';
import DeactivateModal from '~/Accounts/components/DeactivateModal';
import AddEditWithdrawalAddressModal from '~/Accounts/components/AddEditWithdrawalAddressModal';
import { useSelector, useDispatch } from 'react-redux';
import { useGetAccountsDetailsBatchQuery, useGetPayoutAddressQuery } from '~/Accounts/api';
import {
  selectPayoutAddress,
  selectSelectedAccount,
  selectSelectedPayoutCoin,
  selectView
} from '~/Accounts/Accounts.selectors';
import BackToPool from '~/Accounts/components/BackToPool';
import { useGetPoolStatsQuery } from '@/processes/PoolStats';
import { useSupportMiner } from '~/Accounts/hooks/useSupportMiner';
import { toggleActive, toggleSelectedPayoutCoin } from '~/Accounts/Accounts.slice';
import { usernameSelector } from '@/processes/Auth';
import { WatcherLinkNavButton } from '@/features/WatcherLink';
import { IconWarning } from '@/shared/ui/Icons';

const Accounts = () => {
  const { minerAccountOrBatch, accountFromUrl, isSupport } = useSupportMiner();
  const coin = useSelector(selectSelectedPayoutCoin);
  const selectedAccount = useSelector(state => selectSelectedAccount(state, minerAccountOrBatch));

  const accountName = _get(selectedAccount, 'name', null);
  useGetPayoutAddressQuery({ account: accountName, coin }, { skip: !(accountName) });
  const { address, valid, isTag } = useSelector(state => selectPayoutAddress(state, coin));

  const dispatch = useDispatch();
  // fetch all accounts or single account (for support team)
  // and access data via selectors
  useGetAccountsDetailsBatchQuery(minerAccountOrBatch);
  useGetPoolStatsQuery(null);

  const view = useSelector(selectView);
  const username = useSelector(usernameSelector);

  // // toggle preselected account
  useEffect(() => {
    if (username) {
      dispatch(toggleActive(accountFromUrl || username));
      dispatch(toggleSelectedPayoutCoin(null));
    }
  }, [username]); // eslint-disable-line

  const isWarningShow = !isTag && address.length !== 0;

  return (
    <>
      <div className={cx("flex flex-col md:flex-row justify-between mb-10 items-center mx-4 gap-4 mt-8 md:mt-0", {'!items-start': isWarningShow})}>
        <div className="mr-auto flex-shrink-0">
          <BackToPool/>
        </div>
        <div className="flex flex-col items-end">
          {/*TODO: This is a GRAM warning banner. GRAM has been terminated, but this could be used for other coins. */}
          {/*{isWarningShow ? (*/}
          {/*  <Box colorSchema="warning" className="mb-3 flex gap-2 max-w-[640px]">*/}
          {/*    <IconWarning className="flex-shrink-0 mt-[3px]" height={16} width={16}/>*/}
          {/*    <div className="flex-grow">*/}
          {/*      <div className="font-bold text-xs">*/}
          {/*        <FormattedMessage id="gram.warning.title"/>*/}
          {/*      </div>*/}
          {/*      <div className="text-xs">*/}
          {/*        <FormattedMessage id="gram.warning.desc" values={{*/}
          {/*          span: (...chunks) => <span className="text-[#FF9500]">{chunks}</span>,*/}
          {/*        }}/>*/}

          {/*        <div className="mt-3">*/}
          {/*          <FormattedMessage id="gram.warning.desc2"/>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </Box>*/}
          {/*) : null}*/}

          <Box withBorder={false}>
            <WatcherLinkNavButton/>
          </Box>
        </div>
      </div>

      <div className={cx(s.row, s[view])} id="view-container">
        <div className={s.col} id="section-main">
          <div className="mb-6">
            <Main/>
          </div>
          {isSupport ? null : (
            <div className="mb-6">
              <SubaccountsActive />
            </div>
          )}
          {isSupport ? null : (
            <div>
              <SubaccountsInactive />
            </div>
          )}
        </div>
        <Details className={s.col} id="section-details"/>
        <Payouts className={s.col} id="section-payouts"/>
      </div>

      {/* Create sub-account modal */}
      <CreateSubAccountModal/>
      {/* Deactivate sub-account modal */}
      <DeactivateModal/>
      {/* Add or Edit Withdrawal address modal */}
      <AddEditWithdrawalAddressModal/>
    </>
  );
}

export default Accounts;
